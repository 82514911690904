import React from 'react'
import PropTypes from 'prop-types'
import styles from './Error.styles.scss'

const ErrorTemplate = ({ title, children }) => (
  <div className={styles.el}>
    <div className={styles.el__container}>
      <h1 className={styles.el__heading}>{title}</h1>
      <div className={styles.el__message}>{children}</div>
    </div>
  </div>
)

ErrorTemplate.defaultProps = {
  title: undefined,
  children: undefined,
}

ErrorTemplate.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
}

export default ErrorTemplate
